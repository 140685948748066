<template>
  <el-container>
    <el-aside width="200px">
      <edit-tree treeTitle="商品分类" :treeData="treeData" @handleNodeClick="handleCateNodeClick">
      </edit-tree>
    </el-aside>
    <grid-table ref="gridTable" :tableConfig="tableConfig" @selectionChange="handleSelectionChange"
      :buttonConfig="buttonConfig" @handleButtonClick="callMethod" class="product-table">
    </grid-table>
    <edit-dialog ref="editDialog" v-if="dialogVisible" :formConfig="editFormConfig"
      @handleQuery="handleQuery"></edit-dialog>
    <product-edit ref="productEdit" v-if="productEditVisible" :formConfig="editFormConfig"
      @handleQuery="handleQuery"></product-edit>
    <product-edit-more ref="productEditMore" v-if="productEditMoreVisible" @handleQuery="handleQuery"></product-edit-more>
    <single-upload ref="singleUpload" v-if="singleUploadVisible" :formConfig="editFormConfig"
      @handleQuery="handleQuery"></single-upload>
    <sync-plat-product ref="syncPlatProduct" v-if="syncPlatProductVisible" :formConfig="editFormConfig"
      @handleQuery="handleQuery"></sync-plat-product>
    <product-add-to-plat ref="productAddToPlat" v-if="productAddToPlatVisible" :formConfig="editFormConfig"
      @handleQuery="handleQuery"></product-add-to-plat>
    <product-update-price ref="productUpdatePrice" v-if="productUpdatePriceVisible" :formConfig="editFormConfig"
      @handleQuery="handleQuery"></product-update-price>
  </el-container>
</template>

<script>
import { submit, exportFile } from "@/api/base";
import ProductEdit from "./components/ProductEdit.vue";
import ProductEditMore from "./components/ProductEditMore.vue";
import SyncPlatProduct from "./components/SyncPlatProduct.vue";
import ProductAddToPlat from "./components/ProductAddToPlat.vue";
import ProductUpdatePrice from './components/ProductUpdatePrice.vue';

export default {
  name: "productBase",
  components: {
    ProductEdit,
    ProductEditMore,
    SyncPlatProduct,
    ProductAddToPlat,
    ProductUpdatePrice
  },
  data() {
    return {
      buttonConfig: [...(this.$route.meta.pageConfig.buttons || [])],
      tableConfig: { ...(this.$route.meta.pageConfig.table || {}) },
      selectRows: [],
      dialogVisible: false,
      productEditVisible: false,
      productEditMoreVisible: false,
      singleUploadVisible: false,
      syncPlatProductVisible: false,
      productAddToPlatVisible: false,
      productUpdatePriceVisible: false,
      editFormConfig: {},
      treeData: [],
    };
  },
  mounted() {
    this.$nextTick(async () => {
      if (this.tableConfig.searchForm.url) {
        const { data } = await submit(this.tableConfig.searchForm.url);
        const { items } = this.getFormRules(
          this.tableConfig.searchForm.items,
          data.InitData
        );
        this.$set(this.tableConfig.searchForm, "items", items);
      }
      const { data } = await submit("/api/product/category/getTree");
      this.treeData = data;
    });
    this.handleQuery();
  },
  methods: {
    handleCateNodeClick(data) {
      this.$refs.gridTable.searchParam["CateID"] = data.value;
      this.$refs.gridTable.fetchData();
    },
    handleQuery() {
      this.$refs.gridTable.fetchData();
    },
    handleSelectionChange(selection) {
      this.selectRows = selection;
    },
    callMethod(button) {
      let methods = this.$options.methods;
      const _this = this;
      methods[button.method](_this, button);
    },
    search(_this) {
      _this.handleQuery();
    },
    add(_this, button) {
      _this.showProductDialog(_this, "新增", 0, button.bindForm);
    },
    edit(_this, button) {
      if (!_this.selectRows || _this.selectRows.length == 0) {
        _this.$baseMessage("请选择数据", "error");
        return false;
      }
      if (_this.selectRows.length > 1) {
        _this.$baseMessage("只能选择一行", "error");
        return false;
      }
      _this.showProductDialog(
        _this,
        "修改",
        _this.selectRows[0].ProductID,
        button.bindForm
      );
    },
    showProductDialog(_this, title, productID, formConfig) {
      _this.productEditVisible = true;
      _this.$nextTick(() => {
        _this.editFormConfig = { ...formConfig };
        _this.$refs.productEdit.init(
          title,
          productID,
          this.$refs.gridTable.searchParam.CateID || 0
        );
      });
    },
    batchEdit(_this) {
      if (!_this.selectRows || _this.selectRows.length == 0) {
        _this.$baseMessage("请选择数据", "error");
        return false;
      }
      _this.productEditMoreVisible = true;
      _this.$nextTick(() => {
        _this.$refs.productEditMore.init(
          _this.selectRows.map((row) => row.ProductID).join()
        );
      });
    },
    import(_this, button) {
      _this.singleUploadVisible = true;
      _this.$nextTick(() => {
        _this.editFormConfig = { ...button.bindForm };
        _this.$refs.singleUpload.init(button.label);
      });
    },
    sync(_this, button) {
      _this.syncPlatProductVisible = true;
      _this.$nextTick(() => {
        _this.editFormConfig = { ...button.bindForm };
        _this.$refs.syncPlatProduct.init(button.label);
      });
    },
    showDialog(_this, title, button) {
      _this.dialogVisible = true;
      _this.$nextTick(() => {
        _this.editFormConfig = { ...button.bindForm };
        _this.$refs.editDialog.init(title, {
          ProductIDs: _this.selectRows.map((row) => row.ProductID).join(),
        });
      });
    },
    addProductToPlat(_this, button) {
      if (
        button.isNeedSelectRow &&
        (!_this.selectRows || _this.selectRows.length == 0)
      ) {
        _this.$baseMessage("请选择数据", "error");
        return false;
      }
      if (
        button.isNeedSelectRow &&
        !button.isMultiSelectRow &&
        _this.selectRows.length > 1
      ) {
        _this.$baseMessage("只能选择一行", "error");
        return false;
      }
      let productIds = _this.selectRows.map((row) => row.ProductID);
      _this.productAddToPlatVisible = true;
      _this.$nextTick(() => {
        _this.$refs.productAddToPlat.init(productIds);
      });
    },
    updatePlatformPrice(_this, button) {
      if (
        button.isNeedSelectRow &&
        (!_this.selectRows || _this.selectRows.length == 0)
      ) {
        _this.$baseMessage("请选择数据", "error");
        return false;
      }
      if (
        button.isNeedSelectRow &&
        !button.isMultiSelectRow &&
        _this.selectRows.length > 1
      ) {
        _this.$baseMessage("只能选择一行", "error");
        return false;
      }
      let productIds = _this.selectRows.map((row) => row.ProductID);
      _this.productUpdatePriceVisible = true;
      _this.$nextTick(() => {
        _this.$refs.productUpdatePrice.init(productIds);
      });
    },
    handleShowDialog(_this, button) {
      if (
        button.isNeedSelectRow &&
        (!_this.selectRows || _this.selectRows.length == 0)
      ) {
        _this.$baseMessage("请选择数据", "error");
        return false;
      }
      if (
        button.isNeedSelectRow &&
        !button.isMultiSelectRow &&
        _this.selectRows.length > 1
      ) {
        _this.$baseMessage("只能选择一行", "error");
        return false;
      }
      _this.dialogVisible = true;
      _this.$nextTick(() => {
        _this.editFormConfig = { ...button.bindForm };
        _this.$refs.editDialog.init(
          button.label,
          _this.getTableParam(
            _this.selectRows,
            button.isNeedRowKey,
            button.isMultiSelectRow,
            _this.tableConfig.rowKey,
            button.paramJson
          )
        );
      });
    },
    handleToExcute(_this, button) {
      if (
        button.isNeedSelectRow &&
        (!_this.selectRows || _this.selectRows.length == 0)
      ) {
        _this.$baseMessage(`请选择要${button.label}的数据`, "error");
        return false;
      }
      if (
        button.isNeedSelectRow &&
        !button.isMultiSelectRow &&
        _this.selectRows.length > 1
      ) {
        _this.$baseMessage("只能选择一行", "error");
        return false;
      }
      if (button.isNeedConfirm) {
        _this.$baseConfirm(`确定选中项要${button.label}吗？`, null, () => {
          this.handleExecute(_this, button);
        });
      } else {
        this.handleExecute(_this, button);
      }
    },
    async handleExecute(_this, button) {
      const { msg } = await submit(
        button.url,
        _this.getTableParam(
          _this.selectRows,
          button.isNeedRowKey,
          button.isMultiSelectRow,
          _this.tableConfig.rowKey,
          button.paramJson
        )
      );
      if (msg) {
        _this.$baseMessageBox(
          `${button.label}成功，${msg}`,
          `${button.label}提示`
        );
      } else {
        _this.$baseMessage(`${button.label}成功`, "success");
      }
      _this.handleQuery();
    },
    async delete(_this, button) {
      if (!_this.selectRows || _this.selectRows.length == 0) {
        _this.$baseMessage("请选择要删除的数据", "error");
        return false;
      }
      _this.$baseConfirm("确定选中项要删除吗？", null, async () => {
        await submit(button.url, {
          ProductIDs: _this.selectRows.map((row) => row.ProductID).join(),
        });
        _this.$baseMessage("删除成功", "success");
        _this.handleQuery();
      });
    },
    async downloadImportTemplate(_this, button) {
      const { data } = await submit(button.url);
      var a = document.createElement("a");
      a.href = data.Url;
      a.download = data.FileName;
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      a.remove();
    },
    async export(_this, button) {
      let param = {};
      if (
        button.isNeedSelectRow &&
        (!_this.selectRows || _this.selectRows.length == 0)
      ) {
        _this.$baseMessage(`请选择要${button.label}的数据`, "error");
        return false;
      }
      if (
        button.isNeedSelectRow &&
        !button.isMultiSelectRow &&
        _this.selectRows.length > 1
      ) {
        _this.$baseMessage("只能选择一行", "error");
        return false;
      }
      if (!button.isMultiSelectRow) {
        param[_this.tableConfig.rowKey] =
          _this.selectRows[0][_this.tableConfig.rowKey];
      } else {
        param[_this.tableConfig.rowKey + "s"] = _this.selectRows
          .map((row) => row[_this.tableConfig.rowKey])
          .join();
      }
      const { data } = await exportFile(button.url, param);
      const blob = new Blob([data.Blob], { type: "application/vnd.ms-excel" });
      var a = document.createElement("a");
      a.href = URL.createObjectURL(blob);
      a.download = data.FileName;
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      a.remove();
    },
  },
};
</script>
