<template>
  <el-dialog
    :title="title"
    :visible="visible"
    :close-on-click-modal="false"
    :show-close="false"
    :close-on-press-escape="false"
    :width="editForm.dialogWidth"
    @close="close"
  >
    <edit-form ref="editForm" :formConfig="editForm">
      <el-form-item label="同步消息" v-show="progressVisible">
        {{ progressResult }}
      </el-form-item>
      <el-form-item label="同步进度" v-show="progressVisible">
        <el-progress type="circle" :percentage="percentage"></el-progress>
      </el-form-item>
    </edit-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="save" :disabled="progressVisible"
        >同步</el-button
      >
      <el-button @click="close">关闭</el-button>
    </div>
    <div class="progress-wrapper"></div>
  </el-dialog>
</template>
<script>
import { submit } from "@/api/base";

export default {
  name: "SyncPlatProduct",
  props: {
    formConfig: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      visible: false,
      title: "",
      editForm: {},
      param: {},
      progressVisible: false,
      progressResult: "",
      percentage: 0,
      stopSync: true,
    };
  },
  methods: {
    init(title = "", param = {}) {
      this.$nextTick(() => {
        this.title = title;
        this.param = param || {};
        this.editForm = {};
        this.editForm = this.formConfig
          ? {
              ...(this.formConfig || {}),
              data: { ...(this.formConfig.data || {}) },
              items: [...(this.formConfig.items || [])],
            }
          : {};
        this.editForm.data = { ...this.editForm.data, ...this.param };
        this.fetchData();
      });
      this.progressVisible = false;
      this.progressResult = "";
      this.percentage = 0;
      this.stopSync = true;
      this.visible = true;
    },
    async fetchData() {
      let initData = {};
      if (this.editForm.url) {
        const { data } = await submit(this.editForm.url, this.param);
        initData = data.InitData || {};
        if (!this.param.type || this.param.type != "add") {
          this.editForm.data = { ...this.editForm.data, ...data };
        }
      }
      const { items, rules } = this.getFormRules(this.editForm.items, initData);
      this.$set(this.editForm, "items", items);
      this.$set(this.editForm, "rules", rules);
      this.$nextTick(() => {
        this.$refs.editForm.clearValidate();
      });
    },
    save() {
      this.$refs.editForm.validate(async (valid) => {
        if (valid) {
          this.progressResult = "";
          this.percentage = 0;
          this.stopSync = false;
          this.progressVisible = true;
          const processID =
            parseInt(new Date().getTime() / 1000) +
            "" +
            Math.ceil(Math.random() * 10);
          this.sync(processID);
        } else {
          return false;
        }
      });
    },
    async sync(processID) {
      const { data } = await submit(this.editForm.saveUrl, {
        ...this.editForm.data,
        ...{ ProcessID: processID },
      });
      if (!data.success) {
        this.$baseMessage("同步失败，" + data.msg, "error");
        this.progressVisible = false;
        this.stopSync = true;
        return;
      }
      if (data.process >= 100) {
        this.progressVisible = false;
        this.stopSync = true;
        this.$baseMessage("同步成功", "success");
        this.$emit("handleQuery");
        this.close();
        return;
      }
      this.progressResult = data.msg;
      this.percentage = data.process;
      if (!this.stopSync) {
        setTimeout(() => {
          this.sync(processID);
        }, 1000);
      }
    },
    close() {
      this.stopSync = true;
      this.visible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
