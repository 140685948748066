<template>
    <el-dialog :title="title" :visible="visible" :close-on-click-modal="false" width="800px" @close="close">
        <el-form ref="editForm" :model="form" :rules="rules" :inline="false" label-position="right" label-width="100px"
            class="editForm">
            <el-form-item label="铺货进度" v-show="progressVisible">
                <el-progress :text-inside="true" :stroke-width="24" status="success" :percentage="percentage"></el-progress>
            </el-form-item>
            <el-form-item label="" v-show="progressVisible">{{ progressResult }}</el-form-item>
        </el-form>
        <div>
            <grid-table ref="gridTable" :tableConfig="tableConfig" :showPagination="false"
                @selectionChange="selectionChange">
            </grid-table>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="save" :disabled="progressVisible">提交</el-button>
            <el-button @click="close">关闭</el-button>
        </div>
    </el-dialog>
</template>
<script>
import { submit } from "@/api/base";
export default {
    name: "ProductUpdatePrice",
    components: {},
    data() {
        return {
            visible: false,
            progressVisible: false,
            progressResult: "",
            percentage: 0,
            stopSync: true,
            type: "",
            title: "",
            form: {},
            rules: {},
            tableConfig: {
                columns: [
                    {
                        label: "平台",
                        prop: "PlatFormName",
                        type: "text",
                        width: "130px",
                    },
                    {
                        label: "店铺名称",
                        prop: "StoreName",
                        type: "text",
                        width: "220px",
                    },
                    {
                        label: "关联码",
                        prop: "DockCode",
                        type: "text",
                        width: "180px",
                    }
                ],
            },
            selectRows: [],
            productSelectorDialogVisible: false,
        };
    },
    methods: {
        init(productIds) {
            this.type = "修改价格";
            this.title = "修改价格";
            this.visible = true;
            this.$nextTick(() => {
                this.fetchData(productIds);
            });
        },
        async fetchData(productIds) {
            const { data } = await submit("/api/product/getAddProductToPlatInfo", {});
            this.form = { ...data };
            this.form.ProductID = productIds;
            this.$refs.gridTable.setData(this.form.InitData.ShopList);
        },
        selectionChange(selection) {
            this.selectRows = selection;
        },
        save() {
            let selectDatas = this.selectRows;
            if (!this.selectRows || this.selectRows.length == 0) {
                this.$baseMessage("请选择要修改价格的店铺", "error");
                return;
            }
            this.$refs.editForm.validate(async (valid) => {
                if (valid) {
                    let param = { ...this.form };
                    param.InitData = null;
                    param.AInfoID = [];
                    selectDatas.forEach((row) => {
                        param.AInfoID.push(row["AInfoID"]);
                    });
                    this.form = param;

                    this.progressResult = "";
                    this.percentage = 0;
                    this.stopSync = false;
                    this.progressVisible = true;
                    const processID =
                        parseInt(new Date().getTime() / 1000) +
                        "" +
                        Math.ceil(Math.random() * 10);
                    this.sync(processID);
                } else {
                    return false;
                }
            });
        },
        async sync(processID) {
            const { data } = await submit("/api/product/UpdatePlatformPrice", {
                ...this.form,
                ...{ ProcessID: processID },
            });
            if (!data.success) {
                this.$baseMessage("修改价格失败，" + data.msg, "error");
                this.progressVisible = false;
                this.stopSync = true;
                return;
            }
            if (data.process >= 100) {
                this.stopSync = true;
                this.$baseMessage(data.msg, "success");
                setTimeout(() => {
                    this.progressVisible = false;
                    this.$emit("handleQuery");
                    this.close();
                }, 2000);
                return;
            }
            this.progressResult = data.msg;
            this.percentage = data.process;
            if (!this.stopSync) {
                setTimeout(() => {
                    this.sync(processID);
                }, 1000);
            }
        },
        close() {
            this.progressResult = "";
            this.percentage = 0;
            this.stopSync = true;
            this.progressVisible = false;
            this.$refs.editForm.resetFields();
            this.$refs.gridTable.tableData = [];
            this.visible = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.editForm {
    border: 1px solid #ebeef5;
    padding-top: 10px;
    margin-bottom: 10px;
}

.operation {
    border: 1px solid #ebeef5;
    padding: 5px;
    margin-bottom: 10px;

    >div {
        display: inline-block;
        margin: 0 10px;
    }
}
</style>
